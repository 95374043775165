<template>
    <b-card title="Filters" class="mb-0">
        <b-form @submit.prevent>
            <b-row>
                <!-- Date Start -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('apps.reportLedger.singular.dateStart')" label-for="date-start">
                        <flat-pickr id="date-start" :config="dateFormat" v-model="form.dateStart"
                            class="form-control form-control-sm" />
                    </b-form-group>
                </b-col>

                <!-- Date End -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('apps.reportLedger.singular.dateEnd')" label-for="date-start">
                        <flat-pickr id="date-end" :config="dateFormat" v-model="form.dateEnd"
                            class="form-control form-control-sm" />
                    </b-form-group>
                </b-col>
                <!-- Button Filter -->
                <b-col cols="12" md="4">
                    <b-button block variant="primary" @click="getDataDiagram" class="mt-2 pd-1">
                        {{ $t('globalActions.print') }}
                    </b-button>
                </b-col>
                <e-charts autoresize :options="bar" ref="bar" theme="ovilia-green" auto-resize />
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton
} from 'bootstrap-vue'

import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/bar'
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { format, startOfMonth, endOfMonth } from 'date-fns'
// import { format, parse } from 'date-fns'
// import {  startOfMonth, endOfMonth, format } from 'date-fns'


export default {
    setup() {
        const { $get } = useHttp()

        const form = ref({
            dateStart: format(startOfMonth(new Date()), 'dd/MM/yyyy'),
            dateEnd: format(endOfMonth(new Date()), 'dd/MM/yyyy'),
            params: [
                `dateStart=${format(startOfMonth(new Date()), 'dd/MM/yyyy')}`,
                `dateEnd=${format(endOfMonth(new Date()), 'dd/MM/yyyy')}`
            ]
        })

        const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

        const dataDiagram = ref()


        onMounted(() => {
            
        })

        return {
            dataDiagram,
            $get,
            form,
            dateFormat,
            format
        }
    },
    data() {
        return {
            bar: {
                legend: {},
                tooltip: {},
                dataset: {
                    // Provide data.
                    source: [
                    ]
                },
                // Declare X axis, which is a category axis, mapping
                // to the first column by default.
                xAxis: { type: 'category' },
                // Declare Y axis, which is a value axis.
                yAxis: {},
                // Declare several series, each of them mapped to a
                // column of the dataset by default.
                series: [{ type: 'bar' }]
            },
        }
    },
    async mounted() {
        this.dataDiagram = await this.getDataDiagram()
        let dataDiagramBar = []
        this.dataDiagram.data.forEach(field => {
            dataDiagramBar.push([field.brand, (parseFloat(field.penilaian_a) + parseFloat(field.penilaian_b) + parseFloat(field.penilaian_c) + parseFloat(field.penilaian_d)) / 4])
        })
        this.bar.dataset.source = dataDiagramBar
    },
    methods: {
        async getDataDiagram() {
            this.form.params = [
                `dateStart=${this.form.dateStart}`,
                `dateEnd=${this.form.dateEnd}`
            ]
            const data = await this.$get({ url: `transaction/evaluations/diagram?${this.form.params.join('&')}` })
            return data;
        }
    },
    components: {
        BCard,
        BCol,
        BForm,
        BFormGroup,
        BButton,
        flatPickr,
        ECharts,
        BRow
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>